require('./bootstrap');

$('document').ready(function () {

    const reviews_carousel = $('#reviews').owlCarousel({
        margin: 25,
        responsive : {
            0 : {
                items: 1
            },
            576 : {
                items: 2
            },
            1200 : {
                items: 3
            }
        }
    });

    $('.owl_nav_btn').click(function (e) {
        e.preventDefault();
        let nav = this.dataset.owl;
        if (nav == "prev") {
            reviews_carousel.trigger('prev.owl.carousel');
        } else {
            reviews_carousel.trigger('next.owl.carousel');
        }
    });

    /** Карусели материалов **/

    $('#material-carousel').owlCarousel({
        margin: 25,
        loop: true,
        dots: true,
        responsive : {
            0 : {
                items: 1
            },
            576 : {
                items: 2
            },
            1200 : {
                items: 2
            }
        }
    });

    $('#furnitura-carousel').owlCarousel({
        margin: 25,
        loop: true,
        dots: true,
        responsive : {
            0 : {
                items: 1
            },
            576 : {
                items: 2
            },
            1200 : {
                items: 2
            }
        }
    });

    /** Карусель акций **/

    $('#action_block ul').owlCarousel({
        margin: 25,
        autoplay: true,
        dots: true,
        loop: true,
        autoplayHoverPause: true,
        responsive : {
            0 : {
                items: 1
            },
            576 : {
                items: 1
            },
            1200 : {
                items: 2
            }
        }
    });



    /** Мобильное меню **/

    const mobileMenu = $('.mobile_menu_wrapper');

    $('.mobile-menu-button a').click(function (e) {
        e.preventDefault();
        mobileMenu.addClass('mobile_menu_open');
    });

    $('.button_close_menu, .mobile_menu_close_area, .mobile_menu_nav a').click(function () {
        mobileMenu.removeClass('mobile_menu_open');
    });

    /** Обработка заявки на обратный звонок **/

    let name = '',
        phone = '',
        type = '';
    const sendButton = $('.send_req_form');


    $('.name, .phone').on('input', function () {
        const parent = $(this).parents('.callme_index_form');
        const btn = parent.find('.send_req_form');

        name = parent.find('.name').val();
        phone = parent.find('.phone').val();
        type = parent.find('.type').val();

        if (name && phone) {
            btn.removeClass('button-inactive');
        } else {
            btn.addClass('button-inactive');
        }
    });

    sendButton.on('click', function (e) {
        e.preventDefault();
        const data = {
            name: name,
            phone:  phone,
            type: type
        };

        $(this).addClass('button-inactive');
        $(this).text('отправка...');

        const form = $(this).parents('.form-check');

        axios.post('/create-application', data)
            .then(function () {
                form.find('.callme_index_form').addClass('visually-hidden');
                form.find('.alert_done').removeClass('visually-hidden');
            })
            .catch(function () {
                form.find('.callme_index_form').addClass('visually-hidden');
                form.find('.alert_error').removeClass('visually-hidden');
            });
    });

    /** Обработка загрузки изображений **/

    const imageBtn = $('#images_btn');
    const imageInput = $('#images');
    const imagesList = $('#images_list');
    const previewImage = $('#previews_imag');
    const form = $('#image_form');
    const imageListInput = $('#images_array');

    imageBtn.on('click', function (e) {
        e.preventDefault();
        imageInput.click();
    });

    imageInput.on('change', function () {
        imageBtn.text('Идет загрузка...');
        imageBtn.addClass('button-inactive');

        for (let i = 0; i < this.files.length; i++) {

            const data = new FormData();
            data.append('file', this.files[i]);

            axios.post('/upload-images', data)
                .then(function (response) {
                    addImageToList(response.data);
                    imageBtn.text('Добавить фото');
                    imageBtn.removeClass('button-inactive');
                });
        }
    });

    $('#images_list').on('click', '.remove-item',  function (e) {
        e.preventDefault();
        e.stopPropagation();

        const item = $(this).parents('.item-image');
        const data = new FormData();

        data.append('name', item.data('image'));

        axios.post('/delete-images', data)
            .then(function () {
                if (item.hasClass('selected_item')) {
                    item.remove();
                    setFirstActive()
                } else {
                    item.remove();
                }
            })
    });

    function addImageToList(imageName) {
        const item = document.createElement('div');
        const img = document.createElement('img');

        item.classList.add('item-image');
        item.classList.add('m-2');
        item.dataset.image = imageName;
        item.innerHTML = '<a class="remove-item" href="#"><i class="las la-times"></i></a>';

        img.src = '/images/example/' + imageName;
        item.append(img);

        imagesList.append(item);
    }

    $('#images_list').on('click', '.item-image', function () {
        $('.selected_item').removeClass('selected_item');
        $(this).addClass('selected_item');

        const imageName = $(this).data('image');
        previewImage.val(imageName);
    });

    function setFirstActive() {
        const item = $('.item-image').first();
        const imageName = item.data('image');

        item.addClass('selected_item');
        previewImage.val(imageName);
    }

    form.on('submit', function () {
        let images = [];
        $('.item-image').each(function (index, item) {
            images.push($(item).data('image'));
        });

        imageListInput.val(JSON.stringify(images));

        if (!previewImage.val()) setFirstActive();
    });

    /** Поиск на главной **/

    const search =  $('.example_search');
    const searchInput = $('.example_search input');
    const listSearch = $('#index_example_list .row');

    searchInput.focus(function () {
        search.addClass('example_search_focus');
    });

    searchInput.focusout(function () {
        if (!$(this).val()) {
            search.removeClass('example_search_focus');
        }
    });

    searchInput.change(function () {
        const search = $(this).val();

        $('.example-pagination').hide();
        $('.example_nav_list .active').removeClass('active');

        listSearch.html('<p>Идет поиск работ...</p>');

        axios.post('search-examples', {search})
            .then((response) => {
                const examples = response.data;

                if (examples.length <= 0) {
                    listSearch.html('<p>По вашему запросу ничего не найдено</p>');
                } else {
                    listSearch.html('');
                    examples.forEach(item => {
                        const example = createExamplesView(item);
                        listSearch.append(example);
                        refreshFsLightbox();
                    });
                }
            });
    });

    /** Загрузка изображений на главной **/

    const listIndex = $('#index_example_list .row');
    const stepPagination = $('.example-pagination-step');
    const countPagination = $('.example-pagination-count');
    const moreBtn = $('.load-more-button');
    const numOfPage = 6;
    const exampleLoadConf = {
        category_id: 1,
        step: 0
    };

    loadExamples();

    $('.example_nav_list a').click(function (e) {
        e.preventDefault();
        const category_id = $(this).data('id');

        exampleLoadConf.category_id = category_id;
        exampleLoadConf.step = 0;

        $('.example_nav_list .active').removeClass('active');
        $(this).addClass('active');

        searchInput.val('');
        $('.example-pagination').show();
        search.removeClass('example_search_focus');

        loadExamples();
    });

    moreBtn.click(function (e) {
        e.preventDefault();
        exampleLoadConf.step = exampleLoadConf.step + 1;

        loadExamples();
    });

    function loadExamples() {
        const data = new FormData();
        data.append('category_id', exampleLoadConf.category_id);
        data.append('num_of_page', numOfPage);

        if (exampleLoadConf.step == 0) {
            listIndex.html('');
        }

        axios.post('/load-examples', data)
            .then(function (response) {
                let data = response.data;

                renderNavExamples(data);

                const currentList = data[exampleLoadConf.step];

                for (let item in currentList) {
                    const examples = createExamplesView(currentList[item]);
                    listIndex.append(examples);
                    refreshFsLightbox();
                }
            })
    }

    function createExamplesView(example) {

        const example_item = document.createElement('div');
        example_item.classList.add('col-md-6');
        example_item.classList.add('col-lg-4');
        example_item.innerHTML = '<div class="spinner-border text-primary" role="status"></div>';

        const group = 'group'+ example.id;
        const images = JSON.parse(example.images);

        images.forEach(function (item, index) {
            let link;

            if (index == 0) {
                link = createPreviewLink(item, group, example);
            } else {
                link = createSimpleLink(item, group);
            }

            example_item.insertAdjacentHTML("beforeend", link);
        });

        return example_item;
    }

    function createPreviewLink(image, group, example) {
        let exampleObj = {
            image: image,
            group: group,
            title: example.title,
            newPrice: example.price.toLocaleString(),
            oldPrice: (example.price * 1.2).toLocaleString()
        };

        return exampleTemplate(exampleObj);
    }

    function exampleTemplate(example) {
        return `
            <div class="example__item">
                <a
                        href="images/example/${ example.image }"
                        data-fslightbox="${ example.group }"
                        style="background-image: url('images/example/${ example.image }');"
                ></a>
                <div class="example__title">${ example.title }</div>
        
                <div class="example__price">
                    от
                    <div class="example__price_new">
                        ${ example.newPrice }<i class="las la-ruble-sign"></i>
                    </div>
                    <div class="example__price_old">
                        ${ example.oldPrice }<i class="las la-ruble-sign"></i>
                    </div>
                </div>
        
                <div class="example__button">
                    <a href="#" class="button button-yellow modal__open" data-modal="#design">
                        Заказать бесплатный проект
                    </a>
                </div>
            </div>
        `;
    }

    function createSimpleLink(image, group) {
        return `
            <a 
                href="images/example/${image}"
                data-fslightbox="${group}"
            ></a>
        `;
    }
    
    function renderNavExamples(result) {

        const countPages = result.length;
        const stepPages = exampleLoadConf.step + 1;

        stepPagination.text(stepPages);
        countPagination.text(countPages);

        if (countPages == stepPages) {
            moreBtn.addClass('button-inactive');
            moreBtn.text('Все работы показаны');
        } else {
            moreBtn.removeClass('button-inactive');
            moreBtn.text('Показать еще');
        }
    }

    /** Модальное окно **/

    const body = $('body');

    $('.modal__open').click(function (e) {
        e.preventDefault();

        const modalId = $(this).data('modal');

        $(modalId).addClass('modal-main__show');
        body.toggleClass('modal__no-scroll');
    });

    $('.example_list').on('click', 'a.modal__open', function (e) {
        e.preventDefault();

        const modalId = $(this).data('modal');

        $(modalId).addClass('modal-main__show');
        body.toggleClass('modal__no-scroll');
    });

    $('.modal-main__close').click(function (e) {
        e.preventDefault();

        $(this).parent().removeClass('modal-main__show');
        body.toggleClass('modal__no-scroll');
    })
});

